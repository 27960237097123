import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

import { v4 } from 'uuid';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import SearchTransactionTypesQuery
  from '@/modules/my-investment/allianz/transaction-type/application/queries/search-transaction-types-query';
import GetAllianzWithdrawalOperationDatesQuery
  from '@/modules/my-investment/allianz/allianz-operation-dates/application/queries/get-allianz-withdrawal-operation-dates-query';
import SearchAgreementsAsyncQuery
  from '@/modules/my-investment/agreements/application/queries/search-agreements-async-query';
import { CreateTotalWithdrawalCommand } from '@/modules/my-investment/allianz/total_withdrawal/application/commands';

// Domain
import { BankNameFormatter } from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/services';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class DeleteAccountSummaryViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.SEARCH_TRANSACTION_TYPES_QUERY)
  private readonly search_transaction_types_query!: SearchTransactionTypesQuery;

  @Inject(TYPES.GET_ALLIANZ_WITHDRAWALS_OPERATION_DATES_QUERY)
  private readonly get_allianz_withdrawal_dates_query!: GetAllianzWithdrawalOperationDatesQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_AGREEMENTS_ASYNC_QUERY)
  private readonly search_agreements_query!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.CREATE_TOTAL_WITHDRAWAL_COMMAND)
  private readonly create_total_withdrawal_command!: CreateTotalWithdrawalCommand;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly view: Vue;

  readonly i18n_namespace = 'components.user-profile.user-profile-tabs.delete-account.delete-account-summary';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  id = v4();

  is_loading = false;

  information = {
    account: '',
    source: '',
    destination_account: '',
    amount_to_withdraw: 0,
    accreditation_date: '',
  };

  agreement_type_total_withdrawal_id = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get amount_to_withdraw_formatted() {
    return currencyFormat(this.information.amount_to_withdraw);
  }

  constructor(view: Vue) {
    this.view = view;
  }

  loadPosition = async () => {
    try {
      const positions = await this.search_allianz_account_report_query.execute(this.customer_id);
      if (positions.length) {
        this.information.source = positions[0].found_name || '';
        const total_balance = positions.reduce(
          (total, current) => total + current.final_balance_mxn!, 0,
        );
        this.information.amount_to_withdraw = total_balance;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_position'));
    }
  }

  loadAccreditationDate = async () => {
    try {
      const transaction_types = await this.search_transaction_types_query.execute();
      const total_withdrawal_type = transaction_types.find((transaction_type) => transaction_type.name === 'TotalWithdrawal');
      if (total_withdrawal_type) {
        const op_dates = await this.get_allianz_withdrawal_dates_query.execute(
          total_withdrawal_type.id,
        );
        this.information.accreditation_date = this.date_formatter.formatDate(op_dates.accreditation_operation_date, 'DD MMM YYYY');
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.load_accreditation_date'));
    }
  }

  loadAllianzAccount = async () => {
    try {
      const allianz_account = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
      if (allianz_account) {
        this.information.account = allianz_account.policy_number_issued;
        const bank_name_formatted = BankNameFormatter.format(allianz_account.bank);
        this.information.destination_account = `${bank_name_formatted} ****${allianz_account.account.substr(-4)}`;
        await this.loadAccreditationDate();
        await this.loadPosition();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  setAgreementTotalWithdrawalId = async () => {
    const agreements = await this.search_agreements_query.execute();
    const agreement = agreements.find(
      (item) => item.name === 'permission_total_withdrawal_allianz',
    );
    if (agreement) {
      this.agreement_type_total_withdrawal_id = agreement.id;
    }
  }

  confirm = async () => {
    this.is_loading = true;
    try {
      await this.setAgreementTotalWithdrawalId();
      const total_withdrawal = {
        id: this.id,
        customer_id: this.customer_id || '',
        agreement_type_id: this.agreement_type_total_withdrawal_id,
        was_accepted: true,
        accepted_on: this.datetime_value.create().toString() || '',
      };
      await this.create_total_withdrawal_command
        .execute(total_withdrawal);
      this.nextStep();
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.request_total_withdrawal'));
    } finally {
      this.is_loading = false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep', this.information.accreditation_date);
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadAllianzAccount();
    this.is_loading = false;
  }
}
